import { useEffect } from 'react';
import getConfig from 'next/config';
import Request from '@apester/requests';
import { getUserFromRedirectUrl } from '../utils/browser-utils';
import { useStateValue, setUser, setLoading } from '../state/stateProvider';
import { identifyTrackingUser } from '../utils/event-helper';

const { publicRuntimeConfig: { config } } = getConfig();

const useUser = () => {
  const [{ user }, dispatch] = useStateValue();
  
  const applyUser = (userObject) => {
    setUser(dispatch, userObject);
    identifyTrackingUser(userObject);
    setLoading(dispatch, false);
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setLoading(dispatch, true);
        const response = await Request.Get(new URL(`${config.USERS_URL}/user`), {}, { withCredentials: true });
        applyUser(response.payload);
      } catch (e) {
        console.log(e);
        /**
        * in case of use in MFA, a call to "/user" won't get us the user.
        * only the initial post message with creds will.
        * Social signups uses redirect so we have to send the user with the social callback as a qs.
        * */
        const userObject = getUserFromRedirectUrl();
        if (userObject) {
          applyUser(userObject);
          return;
        }
        setUser(dispatch, 'error');
        setLoading(dispatch, false);
      }
    };
    if (!user) {
      checkAuth();
    }
  }, [user, dispatch]);

  return { user };
};

export default useUser;
