// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { Integrations as TracingIntegrations } from '@sentry/tracing';

import SentryRRWeb from '@sentry/rrweb';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || "https://76799b852e5446429bbaef62f46f5638@o569503.ingest.sentry.io/5775262",
  environment: process.env.NODE_ENV,
  integrations: [new SentryRRWeb(), new TracingIntegrations.BrowserTracing()],
  tracesSampleRate: 0.05,
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
