import React from 'react';
import styles from './Header.module.scss';

const Header = () => (
       <div className={styles.header}>
           <img className={styles.logo} src="/logo.svg" alt=""/>
       </div>
);

export default Header;
