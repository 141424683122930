import React, {
  createContext, useContext, useReducer,
} from 'react';

const initialState = {
  user: null,
  isLoading: true,
  isOnboardingSaveLoading: false,
  snackbar: {
    isOpen: false,
    isAutoHide: false,
    message: null,
  },
  filters: {
    needs: [],
    timeline: null,
    filter: null,
  },
  isIframe: false,
  hasHeaderNotification: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setUser':
      return {
        ...state,
        user: action.payload,
      };
    case 'setPublisher':
        return {
          ...state,
          publisher: action.payload,
        };  
    case 'setLoading':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'setOnboardingSaveLoading':
      return {
        ...state,
        isOnboardingSaveLoading: action.payload,
      };
    case 'setSnackbar':
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...action.payload,
        },
      };
    case 'setFilters':
      return {
        ...state,
        filters: action.payload,
      };
    case 'setIsIframe':
      return {
        ...state,
        isIframe: action.payload,
      };
    case 'setHasHeaderNotification':
      return {
        ...state,
        hasHeaderNotification: action.payload,
      };
    default:
      return state;
  }
};

export const StateContext = createContext([]);

export const StateProvider = ({ children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);

export const setFilters = (dispatch, filter) => {
  dispatch({
    type: 'setFilters',
    payload: filter,
  });
};

export const setUser = (dispatch, user) => {
  dispatch({
    type: 'setUser',
    payload: user,
  });
};

export const setPublisher = (dispatch, publisher) => {
  dispatch({
    type: 'setPublisher',
    payload: publisher,
  });
};

export const setLoading = (dispatch, isLoading) => {
  dispatch({
    type: 'setLoading',
    payload: isLoading,
  });
};

export const setOnboardingSaveLoading = (dispatch, isOnboardingSaveLoading) => {
  dispatch({
    type: 'setOnboardingSaveLoading',
    payload: isOnboardingSaveLoading,
  });
};

export const setSnackbar = (dispatch, isOpen, { isAutoHide = false, message = undefined } = {}) => {
  dispatch({
    type: 'setSnackbar',
    payload: { isOpen, isAutoHide, message },
  });
};

export const setIsIframe = (dispatch, isIframe) => {
  dispatch({
    type: 'setIsIframe',
    payload: isIframe,
  });
};

export const setHasHeaderNotification = (dispatch, hasHeaderNotification) => {
  dispatch({
    type: 'setHasHeaderNotification',
    payload: hasHeaderNotification,
  });
};
