import React from 'react';
import styles from './Loader.module.scss';

function Loader() {
  return (
        <div className={styles.overlay}>
          <div className={styles.apeLoaderWrapper}>
          <div className={styles.blobs}>
            <div className={styles.blob} />
            <div className={styles.blob} />
            <div className={styles.apester} />
          </div>
          <img src="/ape-loader-animation.svg" alt="" />
          </div>
        </div>
  );
}

export default Loader;
