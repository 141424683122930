import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import React from 'react';
import { setSnackbar, useStateValue } from '../../state/stateProvider';
import styles from './Snackbar.module.scss';

const DEFAULT_MESSAGE = 'We are doing some maintenance will be back soon ...';
const AUTO_HIDE_DURATION = 6000;

function SlideTransition(props) {
  return <Slide {...props} direction="down" />;
}

const ApeSnackbar = () => {
  const [{ snackbar }, dispatch] = useStateValue();

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar(dispatch, false);
  };

  return (
    <Snackbar
    className={`${styles.snackbar} ${styles.apeSnackbar}`}
    open={snackbar?.isOpen}
    onClose={handleClose}
    message= {
      <span className={styles.snackbarContent}>
      <img src="/icon-info.png" />
      {snackbar?.message || DEFAULT_MESSAGE}
    </span>
    }
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    TransitionComponent={SlideTransition}
    disableWindowBlurListener
    autoHideDuration={snackbar?.isAutoHide ? AUTO_HIDE_DURATION : null}
  />
  );
};
export default ApeSnackbar;
