import React from 'react';
import Header from '../../components/Header/Header';
import Loader from '../../components/Loader/Loader';
import { useStateValue } from '../../state/stateProvider';
import useUser from '../../hooks/use-user';

const SiteLayout = ({ children, className, pageClass = '' }) => {
  const [{ isLoading }] = useStateValue();
  useUser();

  return (
  <div>
    <Header />
    {isLoading && <Loader/>}
    <div className={`screenContainer ${className} ${pageClass}`}>{children}</div>
  </div>
  );
};

export default SiteLayout;
