import { EventsTracker, GA, HS } from '@apester/events';
import { debounce } from 'lodash';

import getConfig from 'next/config';

const { publicRuntimeConfig: { config } } = getConfig();

const EVENTS_TYPES = {
  ELEMENT_CLICKED: 'element_clicked' as 'element_clicked',
  ELEMENT_TYPED: 'element_typed' as 'element_typed',
  ELEMENT_VALUE_SELECTED: 'element_value_selected' as 'element_value_selected',
  ELEMENT_STATE: 'element_state' as 'element_state',
  ERROR_RAISED: 'error_raised' as 'error_raised',
  PAGE_LOADED: 'page_loaded' as 'page_loaded',
  REDIRECT: 'redirect' as 'redirect',
};

const EVENTS_CATEGORIES = {
  TOP_OF_FUNNEL: 'top_of_funnel' as 'top_of_funnel',
  ONBOARDING: 'onboarding' as 'onboarding',
  USE_CASE: 'use_case' as 'use_case',
};

const EVENTS_NAMES = {
  SIGNIN_LOADED: 'signin_loaded',
  VERTICAL_INFO_LOADED: 'vertical_info_loaded',
  COMPANY_INFO_LOADED: 'company_info_loaded',
  APESTER_USAGE_LOADED: 'apester_usage_loaded',
  USERNAME_EMAIL_LOGIN_CLICKED: 'username_email_login_clicked',
  PASSWORD_LOGIN_CLICKED: 'password_login_clicked',
  USER_LOGIN_FACEBOOK_CLICKED: 'user_login_facebook_clicked',
  USER_LOGIN_LINKEDIN_CLICKED: 'user_login_linkedin_clicked',
  USER_LOGIN_GOOGLE_CLICKED: 'user_login_google_clicked',
  CAPTCHA_RESOLVED: 'captcha_resolved',
  USER_LOGIN_CLICKED: 'user_login_clicked',
  FORGOT_PASSWORD_CLICKED: 'forgot_password_clicked',
  USER_LOGIN_FAIL: 'user_login_fail',
  EMAIL_REGISTRATION_CLICKED: 'email_registration_clicked',
  PASSWORD_REGISTRATION_CLICKED: 'password_registration_clicked',
  CONFIRM_PASSWORD_REGISTRATION_CLICKED:
    'confirm_password_registration_clicked',
  USER_REGISTER_FACEBOOK_CLICKED: 'user_register_facebook_clicked',
  USER_REGISTER_LINKEDIN_CLICKED: 'user_register_linkedin_clicked',
  USER_REGISTER_GOOGLE_CLICKED: 'user_register_google_clicked',
  COMPANY_NAME_CLICKED: 'company_name_clicked',
  COMPANY_SIZE_CLICKED: 'company_size_clicked',
  JOB_TITLE_CLICKED: 'job_title_clicked',
  COMPANY_INFO_NEXT_CLICKED: 'company_info_next_clicked',
  VERTICAL_INFO_NEXT_CLICKED: 'vertical_info_next_clicked',
  FINISH_ONBOARDING_CLICKED: 'finish_onboarding_clicked',
  USER_REGISTERED_CLICKED: 'user_registered_clicked',
  USER_REGISTERED_CLICKED_FAILED: 'user_registration_failed',
  PASSWORD_RESET_SUCCESS: 'password_reset_success',
  COMPANY_INFO_NEXT_DISABLED: 'company_info_next_disabled',
  COMPANY_INFO_NEXT_ENABLED: 'company_info_next_enabled',
  VERTICAL_INFO_NEXT_DISABLED: 'vertical_info_next_disabled',
  VERTICAL_INFO_NEXT_ENABLED: 'vertical_info_next_enabled',
  FINISH_ONBOARDING_DISABLED: 'finish_onboarding_disabled',
  FINISH_ONBOARDING_ENABLED: 'finish_onboarding_enabled',
  COMPANY_SIZE_SELECTED: 'company_size_selected',
  VERTICAL_SELECTED: 'vertical_selected',
  APESTER_USAGE_SELECTED: 'apester_usage_selected',
  OLD_USER_REDIRECT_TO_ONBOARDING: 'old_user_redirect_to_onboarding',
  COMPANY_NAME_TYPED: 'company_name_typed',
  JOB_TITLE_TYPED: 'job_title_typed',
  USE_CASE_INPUT_FIELD_TYPED: 'use_case_input_field_typed',
  USE_CASE_INPUT_FIELD_CLICKED: 'use_case_input_field_clicked',
  USE_CASE_CTA_CLICKED: 'use_case_cta_clicked',
  SOCIAL_SHARE_CREATE: 'social_share_create',
  TEMPLATE_PAGE_LOADED: 'template_page_loaded',
};

const BASIC_EVENTS_PROPS = {
  BUTTON: 'button' as 'button',
  CLICK: 'click' as 'click',
  INPUT_FIELD: 'input_field' as 'input_field',
  EXCEPTION: 'exception' as 'exception',
  TYPE: 'type' as 'type',
  ERROR: 'error' as 'error',
  ACTION: 'action' as 'action',
  SELECTION: 'selection' as 'selection',
  SELECTION_FIELD: 'selection_field' as 'selection_field',
  REDIRECT: 'redirect' as 'redirect',
  DISABLED: 'disabled' as 'disabled',
  ENABLED: 'enabled' as 'enabled',
};

const getBasicEventProps = (eventName) => {
  const eventBase = {
    tab: 'portal',
    name: eventName,
    category: EVENTS_CATEGORIES.TOP_OF_FUNNEL,
    type: EVENTS_TYPES.ELEMENT_CLICKED,
    element: null,
    action: null,
  };
  switch (eventName) {
    case EVENTS_NAMES.SIGNIN_LOADED:
    case EVENTS_NAMES.PASSWORD_RESET_SUCCESS:
    case EVENTS_NAMES.TEMPLATE_PAGE_LOADED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.PAGE_LOADED,
        element: BASIC_EVENTS_PROPS.ACTION,
      };
    case EVENTS_NAMES.USERNAME_EMAIL_LOGIN_CLICKED:
    case EVENTS_NAMES.PASSWORD_LOGIN_CLICKED:
    case EVENTS_NAMES.EMAIL_REGISTRATION_CLICKED:
    case EVENTS_NAMES.PASSWORD_REGISTRATION_CLICKED:
    case EVENTS_NAMES.CONFIRM_PASSWORD_REGISTRATION_CLICKED:
      return {
        ...eventBase,
        element: BASIC_EVENTS_PROPS.INPUT_FIELD,
        action: BASIC_EVENTS_PROPS.TYPE,
      };
    case EVENTS_NAMES.CAPTCHA_RESOLVED:
    case EVENTS_NAMES.USER_LOGIN_FACEBOOK_CLICKED:
    case EVENTS_NAMES.USER_LOGIN_LINKEDIN_CLICKED:
    case EVENTS_NAMES.USER_LOGIN_GOOGLE_CLICKED:
    case EVENTS_NAMES.USER_LOGIN_CLICKED:
    case EVENTS_NAMES.FORGOT_PASSWORD_CLICKED:
    case EVENTS_NAMES.USER_REGISTER_FACEBOOK_CLICKED:
    case EVENTS_NAMES.USER_REGISTER_LINKEDIN_CLICKED:
    case EVENTS_NAMES.USER_REGISTER_GOOGLE_CLICKED:
    case EVENTS_NAMES.USER_REGISTERED_CLICKED:
      return {
        ...eventBase,
        element: BASIC_EVENTS_PROPS.BUTTON,
        action: BASIC_EVENTS_PROPS.CLICK,
      };
    case EVENTS_NAMES.USER_LOGIN_FAIL:
    case EVENTS_NAMES.USER_REGISTERED_CLICKED_FAILED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ERROR_RAISED,
        element: BASIC_EVENTS_PROPS.EXCEPTION,
        action: BASIC_EVENTS_PROPS.ERROR,
      };
    case EVENTS_NAMES.COMPANY_INFO_LOADED:
    case EVENTS_NAMES.VERTICAL_INFO_LOADED:
    case EVENTS_NAMES.APESTER_USAGE_LOADED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.PAGE_LOADED,
        element: BASIC_EVENTS_PROPS.ACTION,
        action: BASIC_EVENTS_PROPS.CLICK,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.COMPANY_NAME_CLICKED:
    case EVENTS_NAMES.JOB_TITLE_CLICKED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_CLICKED,
        element: BASIC_EVENTS_PROPS.INPUT_FIELD,
        action: BASIC_EVENTS_PROPS.CLICK,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.COMPANY_INFO_NEXT_CLICKED:
    case EVENTS_NAMES.VERTICAL_INFO_NEXT_CLICKED:
    case EVENTS_NAMES.FINISH_ONBOARDING_CLICKED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_CLICKED,
        element: BASIC_EVENTS_PROPS.BUTTON,
        action: BASIC_EVENTS_PROPS.CLICK,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.COMPANY_SIZE_CLICKED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_CLICKED,
        element: BASIC_EVENTS_PROPS.SELECTION_FIELD,
        action: BASIC_EVENTS_PROPS.CLICK,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.COMPANY_NAME_TYPED:
    case EVENTS_NAMES.JOB_TITLE_TYPED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_TYPED,
        element: BASIC_EVENTS_PROPS.INPUT_FIELD,
        action: BASIC_EVENTS_PROPS.TYPE,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.COMPANY_SIZE_SELECTED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_VALUE_SELECTED,
        element: BASIC_EVENTS_PROPS.SELECTION_FIELD,
        action: BASIC_EVENTS_PROPS.SELECTION,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.VERTICAL_SELECTED:
    case EVENTS_NAMES.APESTER_USAGE_SELECTED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_VALUE_SELECTED,
        element: BASIC_EVENTS_PROPS.BUTTON,
        action: BASIC_EVENTS_PROPS.SELECTION,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.COMPANY_INFO_NEXT_DISABLED:
    case EVENTS_NAMES.FINISH_ONBOARDING_DISABLED:
    case EVENTS_NAMES.VERTICAL_INFO_NEXT_DISABLED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_STATE,
        element: BASIC_EVENTS_PROPS.BUTTON,
        action: BASIC_EVENTS_PROPS.DISABLED,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.COMPANY_INFO_NEXT_ENABLED:
    case EVENTS_NAMES.FINISH_ONBOARDING_ENABLED:
    case EVENTS_NAMES.VERTICAL_INFO_NEXT_ENABLED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_STATE,
        element: BASIC_EVENTS_PROPS.BUTTON,
        action: BASIC_EVENTS_PROPS.ENABLED,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.OLD_USER_REDIRECT_TO_ONBOARDING:
      return {
        ...eventBase,
        type: EVENTS_TYPES.REDIRECT,
        element: BASIC_EVENTS_PROPS.REDIRECT,
        action: BASIC_EVENTS_PROPS.REDIRECT,
        category: EVENTS_CATEGORIES.ONBOARDING,
      };
    case EVENTS_NAMES.USE_CASE_INPUT_FIELD_TYPED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_TYPED,
        element: BASIC_EVENTS_PROPS.INPUT_FIELD,
        action: BASIC_EVENTS_PROPS.TYPE,
        category: EVENTS_CATEGORIES.USE_CASE,
      };
    case EVENTS_NAMES.USE_CASE_INPUT_FIELD_CLICKED:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_CLICKED,
        element: BASIC_EVENTS_PROPS.INPUT_FIELD,
        action: BASIC_EVENTS_PROPS.CLICK,
        category: EVENTS_CATEGORIES.USE_CASE,
      };
    case EVENTS_NAMES.USE_CASE_CTA_CLICKED:
    case EVENTS_NAMES.SOCIAL_SHARE_CREATE:
      return {
        ...eventBase,
        type: EVENTS_TYPES.ELEMENT_CLICKED,
        element: BASIC_EVENTS_PROPS.BUTTON,
        action: BASIC_EVENTS_PROPS.CLICK,
        category: EVENTS_CATEGORIES.USE_CASE,
      };
    default:
      return {
        ...eventBase,
      };
  }
};

export const getSessionId = () => EventsTracker.GetSessionId();

export const identifyTrackingUser = (user) => {
  if (!user) {
    return;
  }

  EventsTracker.IdentifyUser(user);
  HS.identifyUser(user.emails[0]);

  if (window && window.gtag) {
    GA.Identify(user.userId);
  }
};

export const initTrackers = () => {
  EventsTracker.Init({
    trackWithoutUser: true,
    props: { release: config.RELEASE },
    eventsUrl: config.EVENTS_PUBLIC_URL,
  });
  HS.init();

  if (window && !window.gtag) {
    GA.Init();
  }
};

export const trackGA = (eventName, additionalProps = {}) => {
  if (window && window.gtag) {
    GA.TrackEvent({ eventName, additionalProps });
  }
};

const trackEvent = (eventName, properties = {}) => {
  const eventBase = getBasicEventProps(eventName);
  const eventData = { ...eventBase, properties };
  EventsTracker.SendFunnelEvent(eventData);

  const gaEventData = { ...eventBase, ...properties };
  trackGA(eventName, gaEventData);
};

export const debounceTrackEvent = debounce((eventName, props = {}) => trackEvent(eventName, props), 1000);

export default trackEvent;
