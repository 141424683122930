const DESKTOP_SCREEN_WIDTH = 1024;

// eslint-disable-next-line import/prefer-default-export
export const isMobile = () => (
  typeof window !== 'undefined' ? (window?.screen.availWidth < DESKTOP_SCREEN_WIDTH) : false
);

export const getQueryParam = (param) => {
  const urlParams = new URLSearchParams(window?.location.search);
  return urlParams.get(param);
};

export const getWixInstanceId = () => getQueryParam('instanceId');

export const getChannelId = () => getQueryParam('channelId');

export const getIsMFALogin = () => getQueryParam('mfa');

export const getUTMParams = () => {
  const params = ['utm_source', 'utm_campaign', 'utm_medium', 'utm_term'];
  const utmObject = {};
  params.forEach((param) => {
    const paramValue = getQueryParam(param);
    if (paramValue) {
      utmObject[param] = paramValue;
    }
  });
  return utmObject;
};

export const getUserFromRedirectUrl = () => {
  const user = getQueryParam('user');
  return user ? JSON.parse(atob(user)) : null;
};

export const sendPostMessage = (type: string, data: any) => {
  const windowParent = window.parent;
  if (!windowParent) {
    return;
  }

  windowParent.postMessage({ type, data }, '*');
};
